@import '~bootstrap';


@media (max-width: 900px) {
    html {
        font-size: 11px !important;
    }
}

@media (max-width: 600px) {
    html {
        font-size: 10px !important;
    }
}

html {
    height: -webkit-fill-available;
}

* {
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: "Fredoka Variable" !important;
    background: #1C1C1C;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}

#app {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

}
